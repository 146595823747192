import React from 'react'
import AtiLogo from './Images/Gov_Logos/ATI-LOGO.png';
import BafLogo from './Images/Gov_Logos/BAF-LOGO.png';
import DoaiLogo from './Images/Gov_Logos/DOAI-LOGO.png'
import DostLogo from './Images/Gov_Logos/DOST-LOGO.jpg';
import PcafLogo from './Images/Gov_Logos/PCAF-LOGO.png';
import TesdaLogo from './Images/Gov_Logos/TESDA-LOGO.png';
import AcpcLogo from './Images/Gov_Logos/ACPC-LOGO.png';
import BarLogo from './Images/Gov_Logos/BAR-LOGO.png';

const Gov = () => {
    return (
        <div className='grid grid-cols-1 md:grid-cols-4 gap-2 p-4 mx-auto md:m-5'>

            {/*Agricultural Training Institute */}
            <div className='bg-white rounded-xl shadow-sm mt-5 p-6'>
                <div className='flex justify-center  text-green-600'>
                    <img src={AtiLogo} alt='image' class="h-fit md:h-48 gov-image" ></img>
                </div>
                <div class="flex p-2 flex-col">
                    <h3 class="font-bold md:text-4xl text-xl text-center text-green-700 mb-5">
                        <a href='https://ati.da.gov.ph/ati-main/'>Agricultural Training Institute </a>
                    </h3>
                    <p className='text-center font-medium text-lg md:text-2xl text-green-600'>Para sa mga programa at pagsasanay upang isulong ang pag-unlad ng mga rural areas. </p>
                </div>
            </div>

            {/*Bureau of Agriculture and Fisheries Standards */}
            <div className='bg-white rounded-xl shadow-sm mt-10 px-6'>
                <div className='flex justify-center  text-green-600'>
                    <img src={BafLogo} alt='image' class="h-fit md:h-48 gov-image" ></img>
                </div>
                <div class="flex p-4 flex-col">
                    <h3 class="font-bold md:text-4xl text-2xl text-center text-green-700 mb-5">
                        <a href='http://www.bafs.da.gov.ph/'>Bureau of Agriculture and Fisheries Standards</a>
                    </h3>
                    <p className='text-center font-medium text-green-600 text-xl'>Para sa mga pamantayan ng kalidad patungkol sa mga produktong pang-agrikultura at pangisdaan, istraktura, at makinarya</p>
                </div>
            </div>

            {/* Department of Agriculture - Ilocos */}
            <div className='bg-white rounded-xl shadow-sm mt-10 px-6'>
                <div className='flex justify-center  text-green-600'>
                    <img src={DoaiLogo} alt='image' class="object-fit gov-image" ></img>
                </div>
                <div class="flex p-8 flex-col">
                    <h3 class="font-bold md:text-4xl text-2xl text-center text-green-700 mb-5">
                        <a href='https://ilocos.da.gov.ph/'>Department of Agriculture - Ilocos </a>
                    </h3>
                    <p className='text-center text-xl text-green-600'>Para sa pagpapa-unlad ng ekonomiya sa pamamagitan ng agrikultura mula sa Region 1.</p>
                </div>
            </div>

            {/* Department of Sciene and Technology */}
            <div className='bg-white rounded-xl shadow-sm mt-10 px-6'>
                <div className='flex justify-center  text-green-600'>
                    <img src={DostLogo} alt='image' class="gov-image h-fit md:h-48" ></img>
                </div>
                <div class="flex p-8 flex-col">
                    <h3 class="font-bold md:text-4xl text-2xl text-center text-green-700 mb-5">
                        <a href='https://region1.dost.gov.ph/'>Department of Sciene and Technology </a>
                    </h3>
                    <p className='text-center text-xl text-green-600'>Kumuha ng impormasyon tungkol sa pinakabagong teknolohiya at pamamaraan sa loob ng Region 1.</p>
                </div>
            </div>

            {/* Philippine Council for Agriculture and Fisheries */}
            <div className='bg-white rounded-xl shadow-sm mt-10 px-6'>
                <div className='flex justify-center  text-green-600'>
                    <img src={PcafLogo} alt='image' class="h-fit md:h-48 gov-image" ></img>
                </div>
                <div class="flex p-8 flex-col">
                    <h3 class="font-bold md:text-4xl text-2xl text-center text-green-700 mb-5">
                        <a href='http://www.pcaf.da.gov.ph/'>Philippine Council for Agriculture and Fisheries </a>
                    </h3>
                    <p className='text-center text-xl text-green-600'>Para sa mga participatory na mga proseso sa sekto ng agrikultura at pangingisda.</p>
                </div>
            </div>

            {/* Technical Education and skills Authority  */}
            <div className='bg-white rounded-xl shadow-sm mt-10 px-6'>
                <div className='flex justify-center  text-green-600'>
                    <img src={TesdaLogo} alt='image' class="h-fit md:h-48 gov-image" ></img>
                </div>
                <div class="flex p-8 flex-col">
                    <h3 class="font-bold md:text-4xl text-2xl text-center text-green-700 mb-5">
                        <a href='https://tesda.gov.ph/'>Technical Education and skills Authority </a>
                    </h3>
                    <p className='text-center text-xl text-green-600'>Magbasa nang higit pa tungkol sa mga programa sa pagpapaunlad ng teknikal at kasanayan sa Pilipinas</p>
                </div>
            </div>

            {/* Agricultural Credit Policy Council*/}
            <div className='bg-white rounded-xl shadow-sm mt-10 px-6'>
                <div className='flex justify-center  text-green-600'>
                    <img src={AcpcLogo} alt='image' class="h-fit md:h-48 gov-image" ></img>
                </div>
                <div class="flex p-8 flex-col">
                    <h3 class="font-bold md:text-4xl text-2xl text-center text-green-700 mb-5">
                        <a href='https://acpc.gov.ph/'>Agricultural Credit Policy Council </a>
                    </h3>
                    <p className='text-center text-xl text-green-600'>Alamin ang mga polisiyang agricultural credit policies na pinapatupad.</p>
                </div>
            </div>

            {/* Bureau Of Agricultural Research*/}
            <div className='bg-white rounded-xl shadow-sm mt-10 px-6'>
                <div className='flex justify-center  text-green-600'>
                    <img src={BarLogo} alt='image' class="h-fit md:h-48 gov-image" ></img>
                </div>
                <div class="flex p-8 flex-col">
                    <h3 class="font-bold md:text-4xl text-2xl text-center text-green-700 mb-5">
                        <a href='https://bar.gov.ph/'>Bureau Of Agricultural Research </a>
                    </h3>
                    <p className='text-center text-xl text-green-600'>Alamin ang mga bagong pagsasaliksik na magagamit sa sektor ng pagsasaka.</p>
                </div>
            </div>

        </div>
    )
}

export default Gov