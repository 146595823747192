import React from 'react';
import {  Link as Routerlink } from 'react-router-dom';
import { FaFacebookSquare, FaInstagram, FaYoutube, } from "react-icons/fa";

const footer = () => {
    return (
        <div className='static inset-x-0 bottom-0'>
            {/* Footer Nav */}
            <div className='flex flex-col md:flex-row justify-center align-items-center mx-auto align-center text-2xl bg-green-900 text-white '>
                <Routerlink to='/' className=' hover:text-yellow-500 cursor-pointer  mt-4 '>HOME</Routerlink>
                <Routerlink to="/OurProgram" className='= hover:text-yellow-500  cursor-pointer  mt-4'>AMING MGA PROGRAMA</Routerlink>
                <Routerlink to="/Loan" className=' hover:text-yellow-500  cursor-pointer  mt-4'>PAG-UTANG</Routerlink>
                <Routerlink to="/Research" className=' hover:text-yellow-500 cursor-pointer  mt-4'>KAALAMAN</Routerlink>
                <Routerlink to="/Gov" className=' hover:text-yellow-500  cursor-pointer  mt-4'>USEFUL GOV LINKS</Routerlink>
                <Routerlink to="/Contact" className=' hover:text-yellow-500  cursor-pointer  mt-4'>CONTACT US</Routerlink>

            </div>
            {/* Social Media Icons */}
            <div className='flex flex-row justify-center items-center bg-green-900 text-white'>
                <Routerlink to="/" className="p-2 m-2 hover:text-yellow-500"><FaFacebookSquare size='2em' /></Routerlink>
                <Routerlink to="/" className="p-2 m-2 hover:text-yellow-500"><FaInstagram size='2em'  /></Routerlink>
                <Routerlink to="/" className="p-2 m-2 hover:text-yellow-500"><FaYoutube size='2em' /></Routerlink>
            </div>
            {/* Copyright Text */}
            <div className='flex flex-row justify-center items-center bg-green-900 text-white'>
                <p className='opacity-25'>©2022 E-AGRO. All Rights Reserved</p>
            </div>
        </div>
    );
};


export default footer;
