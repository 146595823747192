import React from 'react'
import { Nav } from 'react-bootstrap'
import { Link as Routerlink } from 'react-router-dom'
const Dropdown = () => {
    return (
        <Nav className=" flex-col bg-yellow-500 text-white space-y-2 font-bold block md:hidden ">
            <div className='text-center items-center flex flex-col py-2 my-2 text-xl'>
                <Routerlink to='/' className=' cursor-pointer rounded-md mt-2 '>HOME</Routerlink>
                <Routerlink to="/OurProgram" className='  cursor-pointer rounded-md mt-2 '>AMING MGA PROGRAMA</Routerlink>
                <Routerlink to="/Loan" className='  cursor-pointer rounded-md mt-2'>PAG-UTANG</Routerlink>
                <Routerlink to="/Research" className=' cursor-pointer rounded-md mt-2'>KAALAMAN</Routerlink>
                <Routerlink to="/Gov" className='  cursor-pointer rounded-md mt-2'>USEFUL GOV LINKS</Routerlink>
                <Routerlink to="/Contact" className=' cursor-pointer rounded-md mt-2'>CONTACT US</Routerlink>
            </div>

            <div className='flex flex-row gap-4 py-2 content-center justify-center mx-auto mb-2'>
                <div>
                    {/* LOGIN */}
                    <a href="http://farmer.e-agro.ph/index.php " class="inline-flex items-center bg-green-500 text-white font-bold border-0 py-2 px-3  rounded text-base mt-4 md:mt-0">Login</a>
                </div>

                <div>
                    {/* REGISTER */}
                    <a href="http://farmer.e-agro.ph/verify.php" class="inline-flex items-center bg-green-500 text-white font-bold border-0 py-2 px-3  rounded text-base mt-4 md:mt-0">Register</a>
                </div>

            </div>

        </Nav>
    )
}

export default Dropdown