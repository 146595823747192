


import React, { useState } from 'react';
import { Link as Routerlink } from 'react-router-dom'
import Logo from './Images/LOGO.png';
import { Link, animateScroll as scroll } from "react-scroll";
import Dropdown from './Dropdown';


const Navbar = () => {

    const [isOpen, setIsOpen] = useState(false)

    return (
        <nav className="sticky top-0 bg-white shadow-md z-50">
            <div class="container mx-auto flex flex-wrap flex-col md:flex-row items-center">

                <img className='object-scale-down h-28 w-36 md:object-contain md:h-38 md:w-46' src={Logo} alt="" />

                <nav class="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l text-xl font-medium md:border-gray-400 flex-wrap items-center justify-center md:block hidden">
                    <Routerlink to='/' className='mr-5 hover:text-green-600 hover:outline outline-3 outline-offset-2 cursor-pointer rounded-md '>HOME</Routerlink>
                    <Routerlink to="/OurProgram" className='mr-5 hover:text-green-600 hover:outline outline-3 outline-offset-2 cursor-pointer rounded-md '>AMING MGA PROGRAMA</Routerlink>
                    <Routerlink to="/Loan" className='mr-5 hover:text-green-600 hover:outline outline-3 outline-offset-2 cursor-pointer rounded-md'>PAG-UTANG</Routerlink>
                    <Routerlink to="/Research" className='mr-5 hover:text-green-600 hover:outline outline-3 outline-offset-2 cursor-pointer rounded-md'>KAALAMAN</Routerlink>
                    <Routerlink to="/Gov" className='mr-5 hover:text-green-600 hover:outline outline-3 outline-offset-2 cursor-pointer rounded-md'>USEFUL GOV LINKS</Routerlink>
                    <Routerlink to="/Contact" className='mr-5 hover:text-green-600 hover:outline outline-3 outline-offset-2 cursor-pointer rounded-md'>CONTACT US</Routerlink>

                </nav>

                <div className='space-x-1 mb-2 mx-auto align-center justify-center md:block hidden'>
                    <div className='inline-flex items-center'>
                        {/* LOGIN */}
                        <a href="http://farmer.e-agro.ph/index.php " class=" bg-yellow-500 text-white font-bold border-0 py-2 px-3 focus:outline-none hover:bg-yellow-700 rounded text-base mt-4 md:mt-0">Login</a>
                    </div>

                    <div className='inline-flex items-center '>
                    <a href="http://farmer.e-agro.ph/verify.php" class="bg-yellow-500 text-white font-bold border-0 py-2 px-3 focus:outline-none hover:bg-yellow-700 rounded text-base mt-4 md:mt-0">Register</a>
                    </div>
                    {/* REGISTER */}
                   
                </div>
                <div className=" flex items-center md:block mt-2 mb-2" >
                    <button onClick={() => setIsOpen(!isOpen)} className="bg-yellow-500 text-white font-bold py-2 px-3 rounded-md md:hidden">Menu</button>
                </div>
            </div>
            {
                isOpen ? <Dropdown /> : <div className='sm:hidden'></div>
            }

        </nav>


    );


};

export default Navbar;