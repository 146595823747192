import React from 'react';

const Farmer = () => {
  return (



    <section id='OurProgram'>
      <div className='flex flex-col w-full items-center mt-12 mb-12'>
        <h1 className='mt-5 text-green-600 font-bold text-center  text-5xl md:text-6xl lg:text-8xl'>AMING MGA PROGRAMA</h1>
        <div className='grid grid-cols-1 p-3 md:grid-cols-4 grid-rows-2 gap-4 mt-10 m-8 '>
          {/* Cards */}
          <div className='bg-white rounded-xl shadow-sm mt-10 p-10'>
            <div className='flex justify-center  text-green-600'>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
              </svg>
            </div>
            <div class="flex p-8 flex-col">
              <h3 class="font-bold md:text-2xl sm:text-lg text-center text-green-700 mb-5">HUMIRAM NANG MAY MABABANG TUBO</h3>
              <p className='max-w-md sm:text-lg md:text-xl'>
                <ol class='list-disc'>
                  <li>Mababang interes (1.5% interes/buwan)</li>
                  <li>Tumanggap ng pondo para sa bawat produkto</li>
                  <li className='list-none text-sm py-2'>Rice - Maximum of PHP 100,000/hectare</li>
                  <li className='list-none text-sm py-2'>Corn - Maximum of PHP 100,000/hectare</li>
                  <li className='list-none text-sm py-2'>Onion - Up to  PHP 100,000/hectare</li>
                  <li>Walang sangla o kolateral</li>
                  <li>Gamitin ang pananim bilang pambayad-utang</li>
                  <li>Mabilis na pag proseso</li>
                  <li>Walang nakatagong dagdag bayad</li>
                </ol>
              </p>

            </div>
          </div>
          <div className='bg-white rounded-xl shadow-sm mt-10 p-10'>
            <div className='flex justify-center  text-green-600'>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div class="flex p-8 flex-col">
              <h3 class="font-bold md:text-2xl sm:text-lg text-center text-green-700 mb-5">	MABABABANG PRESYO NG MGA GAMIT</h3>
              <p className='max-w-md sm:text-lg md:text-xl'>
                <ol class='list-disc'>
                  <li>Bumili ang mga kinakailangan online sa murang halaga</li>
                  <li>Tanggapin ang pinamili sa pinakamalapit na warehouse</li>
                </ol>
              </p>

            </div>
          </div>
          <div className='bg-white rounded-xl shadow-sm mt-10 p-10'>
            <div className='flex justify-center  text-green-600'>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
              </svg>
            </div>
            <div class="flex p-8 flex-col">
              <h3 class="font-bold md:text-2xl sm:text-lg text-center text-green-700 mb-5">TAMANG PRESYUHAN SA MERKADO</h3>
              <p className='max-w-md sm:text-lg md:text-xl'>
                <ol class='list-disc'>
                  <li>Ibenta ang ani sa maayos na presyo	</li>
                  <li>Alamin ang bagong presyuhan sa merkado</li>
                </ol>
              </p>

            </div>
          </div>
          <div className='bg-white rounded-xl shadow-sm mt-10 p-10'>
            <div className='flex justify-center  text-green-600'>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
              </svg>
            </div>
            <div class="flex p-8 flex-col">
              <h3 class="font-bold md:text-2xl sm:text-lg text-center text-green-700 mb-5">	PAGTULONG SA PAG-AANI</h3>
              <p className='max-w-md sm:text-lg md:text-xl'>
                <ol class='list-disc'>
                  <li>Mga lugar kung saan maaring ibenta ang inyong ani</li>
                  <li>Mga warehouse upang kunin ang mga pinamiling kagamitan</li>
                  <li>Sementong pwede patuyuan ng palay </li>
                  <li>Gilingan</li>
                </ol>
              </p>

            </div>
          </div>
          <div className='bg-white rounded-xl shadow-sm mt-10 p-10'>
            <div className='flex justify-center  text-green-600'>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
            </div>
            <div class="flex p-8 flex-col">
              <h3 class="font-bold md:text-2xl sm:text-lg text-center text-green-700 mb-5">MECHANIZED FARMING</h3>
              <p className='max-w-md sm:text-lg md:text-xl'>
                <ol class='list-disc'>
                  <li>Pinababang presyo sa paggawa</li>
                  <li>Pinataas na produksyon sa konting paggawa</li>
                  <li>Pagpapa-renta ng makinarya </li>
                </ol>
              </p>

            </div>
          </div>
          <div className='bg-white rounded-xl shadow-sm mt-10 p-10'>
            <div className='flex justify-center  text-green-600'>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
              </svg>
            </div>
            <div class="flex p-8 flex-col">
              <h3 class="font-bold md:text-2xl sm:text-lg text-center text-green-700 mb-5">Crop Insurance</h3>
              <p className='max-w-md sm:text-lg md:text-xl'>
                <ol class='list-disc'>
                  <li>May proteksyon ang inyong pananim laban sa pagkalugi dahil sa mga sakuna.</li>
                </ol>
              </p>

            </div>
          </div>
          <div className='bg-white rounded-xl shadow-sm mt-10 p-10'>
            <div className='flex justify-center  text-green-600'>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
              </svg>
            </div>
            <div class="flex p-8 flex-col">
              <h3 class="font-bold md:text-2xl sm:text-lg text-center text-green-700 mb-5">MODERN FARMING KNOWLEDGE</h3>
              <p className='max-w-md sm:text-lg md:text-xl'>
                <ol class='list-disc'>
                  <li>Patuloy na pagsasaliksik at pagbibigay kaalaman ng Municipal Agricultural Office ng Bayambang.</li>
                  <li>GEOGRAPHICAL INFORMATION SYSTEM (GIS) at SOIL ANALYSIS na magtutukoy sa bawat lugar sa Bayambang, kung anong lupa ang mayroon, at kung anu-ano ang madalas itinatanim doon.</li>
                </ol>
              </p>

            </div>
          </div>

        </div>
      </div>
    </section>

  );
};

export default Farmer;
